import React, { useState } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import useOwnUser from '../../../../../../hooks/useOwnUser'
import { useUpdateVacationMutation } from '../../../../../../features/vacations/vacationsApiSlice'
import { IApprovalRejectionProps } from '../../../../../../pkg/UI'
import { Wrapper, Form, AreaForm } from './rejectElements'

const RejectionDialog = ({ ...props }: IApprovalRejectionProps): JSX.Element => {
  const { t } = useTranslation()
  const user = useOwnUser()
  const [updateRequest] = useUpdateVacationMutation()
  const [form, setForm] = useState<string>('')

  const handleSubmit = (e: any): void => {
    e.preventDefault()
    const data = { ...props.request }
    data.status = 'rejected'
    data.decision_maker_id = user.userUID as number
    data.decision_date = dayjs(new Date()).toISOString()
    data.rejection_reasoning = form
    updateRequest(data)
      .then((res: any) => {
        console.log(res)
      })
      .then(() => props.refetch())
      .then(() => props.handleClose())
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    setForm(e.target.value)
  }

  if (!props.isOpen) return <></>

  return (
    <Wrapper>
      <Form>
        <h1>{t('CompanyHistory:Reject.rejectVacation')}</h1>
        <AreaForm>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor='additional_info'>{t('CompanyHistory:Reject.additionalInfo')}</label>
              <textarea
                rows={4}
                id='additional_info'
                name='additional_info'
                value={form}
                onChange={handleChange}
              />
            </div>
          </form>
        </AreaForm>
        <div className='btn'>
          <button
            className='cancel'
            onClick={props.handleClose}
          >
            {t('CompanyHistory:Reject.cancel')}
          </button>
          <button
            className='reject'
            onClick={handleSubmit}
          >
            {t('CompanyHistory:Reject.reject')}
          </button>
        </div>
      </Form>
    </Wrapper>
  )
}

export default RejectionDialog
