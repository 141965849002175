import useOwnUser from "../../hooks/useOwnUser"

interface RequireRoleProps {
    allowedRoles: string[],
    children: React.ReactNode
}

const RequireRole: React.FC<RequireRoleProps> = ({ allowedRoles, children }) => {
    const {
        groups: role,
        selectedCompanyID
    } = useOwnUser()

    if ( role && selectedCompanyID && allowedRoles?.includes(role) || role === 'admin' || role === 'dev') return <>{children}</>
    
    return null
}

export default RequireRole