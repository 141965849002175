import { use } from 'i18next'
import i18n from 'i18next'
import Cookies from 'js-cookie'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'


// PL
import companyHistoryPL from './static/dictionaries/companyHistory/pl.json'
import vacationRequestPL from './static/dictionaries/vacationRequest/pl.json'
import employeesPL from './static/dictionaries/employees/pl.json'
import historyVacationPL from './static/dictionaries/historyVacation/pl.json'
import rootPL from './static/dictionaries/root/pl.json'
import JobMarketPL from './static/dictionaries/jobMarket/pl.json'

//EN
import companyHistoryEN from './static/dictionaries/companyHistory/en.json'
import vacationRequestEN from './static/dictionaries/vacationRequest/en.json'
import employeesEN from './static/dictionaries/employees/en.json'
import historyVacationEN from './static/dictionaries/historyVacation/en.json'
import rootEN from './static/dictionaries/root/en.json'
import JobMarketEN from './static/dictionaries/jobMarket/en.json'

const languageCookie = Cookies.get('lang')
i18n

  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    lng: languageCookie,
    ns: ['CompanyHistory', 'Employees', 'HistoryVaction', 'VacationRequest', 'Root', 'JobMarket'],
    supportedLngs: ['en', 'pl'],
    resources: {
      pl: {
        CompanyHistory: { ...companyHistoryPL },
        VacationRequest: { ...vacationRequestPL },
        Employees: { ...employeesPL },
        historyVacation: { ...historyVacationPL },
        Root: { ...rootPL },
        JobMarket: { ...JobMarketPL },
      },

      en: {
        CompanyHistory: { ...companyHistoryEN },
        VacationRequest: { ...vacationRequestEN },
        Employees: { ...employeesEN },
        historyVacation: { ...historyVacationEN },
        Root: { ...rootEN },
        JobMarket: { ...JobMarketEN },
      },
    },
    detection: {
      order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
      cookieMinutes: 60 * 24 * 365,
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
    },
    interpolation: {
      escapeValue: false,
    },
  })
