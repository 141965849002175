export const theme = {
  primary: {
    lighter: '#b3e5fc', // Lighter shade of blue
    main: '#29b6f6', // Primary blue
    darker: '#0288d1', // Darker blue
  },
  background: {
    default: '#EBEBEB', // Light grey for default background
    white: '#f6f6f6', // Almost white for sections
  },
  text: {
    default: '#0C171D', // Dark blueish-black for primary text
    light: '#696969', // Light grey for secondary text
    dark: '#1959a1', // Darker blueish-black for headings
    orange: '#C26F52', // Accent color for warnings or highlights
    green: '#1a9122', // Accent color for success messages
  },
  button: {
    light: '#1959a1', // Main blue for buttons
    blue: '#357ABD', // Alternative blue for buttons
    hoverBlue: '#0056b3', // Darker blue for button hover state
  },
  gradients: {
    primary: 'linear-gradient(to right, #1959a1, #4f99d6)', // Gradient for primary sections
    secondary: 'linear-gradient(to right, #3a82a8, #93a9b7,#a2887f)', // Secondary gradient
  },
}
