import { useState, createContext } from 'react'


export enum AlertType {
    Success = 'success',
    Error = 'error',
    Warning = 'warning',
    Info = 'info'
}

interface IServerResponseContext {
    alertType: AlertType,
    showResponse: boolean,
    responseMessage: string | null,
    setAlertType: (type: AlertType) => void,
    setShowResponse: (show: boolean) => void,
    setResponseMessage: (message: string) => void
}

const defaultServerResponseContext: IServerResponseContext = {
    alertType: AlertType.Error, 
    showResponse: false,
    responseMessage: null,
    setAlertType: () => {},
    setShowResponse: () => {},
    setResponseMessage: () => {},
}

const ServerResponseContext = createContext<IServerResponseContext>(defaultServerResponseContext)

export const ServerResponseProvider = ({ children }: { children: React.ReactNode }) => {
    const [alertType, setAlertType] = useState<AlertType>(AlertType.Error)
    const [showResponse, setShowResponse] = useState<boolean>(false)
    const [responseMessage, setResponseMessage] = useState<string | null>(null)

    return (
        <ServerResponseContext.Provider value={{ 
            alertType, 
            showResponse,
            responseMessage, 
            setAlertType,  
            setShowResponse, 
            setResponseMessage 
        }}>
            {children}
        </ServerResponseContext.Provider>
    )
}

export default ServerResponseContext