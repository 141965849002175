import { Outlet } from "react-router";
import Alert from "../../components/Alert";
import useServerResponse from "hooks/useServerResponse";
import { CopyRight, AuthLayoutWrapper } from "./authLayoutElements";

const AuthLayout: React.FC = () => {
  const { showResponse, responseMessage, setShowResponse } =
    useServerResponse();

  return (
    <AuthLayoutWrapper>
      <Outlet />
      <CopyRight>
        Powered by{" "}
        <a href={"https://onitsoft.com/en"} target={"_blank"}>
          Onit Soft
        </a>
      </CopyRight>
      {showResponse && (
        <Alert
          message={responseMessage ?? ""}
          color={"error"}
          show={showResponse}
          handleClose={() => setShowResponse(false)}
        />
      )}
    </AuthLayoutWrapper>
  );
};

export default AuthLayout;
