import { styled } from 'styled-components'

export const Wrapper = styled.main`
  color: #5e71a1;
  text-align: center;

  & a {
    background-color: #5e71a1;
    color: #fff;
    border: 2px solid #5e71a1;
    text-decoration: none;
    border-radius: 50px;
    padding: 10px 20px;
    text-align: center;
    display: inline-block;
    position: relative;
    top: 30px;
    cursor: pointer;
  }
`

export const FoundWrapper = styled.div`
  & img {
    width: 35rem;
    height: 35rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`
