import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  box-shadow: 24px;
  padding: 16px;
`

export const Form = styled.div`
  background: linear-gradient(to bottom, #6495ed, #4682b4);
  border-radius: 10px;
  padding: 20px;
  width: 500px;
  text-align: center;
  color: white;
  position: relative;

  h1 {
    margin-bottom: 20px;
    font-size: 20px;
  }

  .btn {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    button {
      width: 45%;
      padding: 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;

      &.cancel {
        background: #ff0000;
        color: white;
      }

      &.reject {
        background: #32cd32;
        color: white;
      }
    }
  }
`

export const AreaForm = styled.div`
  form {
    div {
      margin-bottom: 20px;

      label {
        display: flex;
        margin-bottom: 10px;
        font-size: 16px;
      }

      textarea {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: none;
        resize: none;
        color: black;
      }
    }
  }
`
