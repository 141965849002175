import { styled } from 'styled-components'

export const MainLayoutWrapper = styled.main`
    width: 90dvw;
    min-height: 100dvh;
    margin: 0 auto;
`

export const Content = styled.section`
    padding-top: 3rem;
`