import { useState, useEffect } from 'react'
import { useGetUserVacationRequestsQuery } from '../../../../../../features/vacations/vacationsApiSlice'
import dayjs from 'dayjs'
import { Wrapper } from './userVacationElements'
import { t } from 'i18next'
import Tabels from '../../../../../root/components/Table'

const EmployeeVacationHistory = ({ ...props }: { userId: string; companyId: number }) => {
  const columns = [
    { header: t('CompanyHistory:History.fullName'), accessor: 'full_name' },
    { header: t('CompanyHistory:History.type'), accessor: 'type' },
    { header: t('CompanyHistory:History.startingDate'), accessor: 'starting_date' },
    { header: t('CompanyHistory:History.finishingDate'), accessor: 'finishing_date' },
    { header: t('CompanyHistory:History.hours'), accessor: 'hours' },
    { header: t('CompanyHistory:History.createdAt'), accessor: 'created_at' },
    { header: t('CompanyHistory:History.status'), accessor: 'status' },
  ]

  const [selectedUserId, setSelectedUserId] = useState<string | null>(null)

  const {
    data: vacationRequests,
    isSuccess: isVacationRequestsSuccess,
    refetch: refetchVacationRequests,
    isFetching: isVacationRequestsFatching,
  } = useGetUserVacationRequestsQuery(
    { userID: Number(props.userId), companyID: props.companyId },
    { skip: !props.userId },
  )

  useEffect(() => {
    if (isVacationRequestsSuccess) refetchVacationRequests()
  }, [props.userId])

  const handleSelectUser = (userId: string) => {
    setSelectedUserId(userId)
  }
  if (!isVacationRequestsSuccess) return <></>

  const statusToColor = {
    pending: '#fcba03',
    approved: 'green',
    rejected: 'red',
    cancelled: 'blue',
  }

  const cellRenderer = (dataKey: string, row: any): JSX.Element => {
    const getColor = (status: string) =>
      statusToColor[status as keyof typeof statusToColor] || 'inherit'

    if (dataKey === 'starting_date' || dataKey === 'finishing_date' || dataKey === 'created_at') {
      return <span>{formatDate(row[dataKey])}</span>
    }
    if (dataKey === 'type') {
      return <span>{getVacationType(row[dataKey])}</span>
    }
    if (dataKey === 'status') {
      return (
        <span style={{ color: getColor(row.status) }}>
          {t('historyVacation:HistoryUser.' + row[dataKey])}
        </span>
      )
    }
    return <span>{row[dataKey]}</span>
  }

  if (isVacationRequestsFatching) return <></>

  return (
    <Wrapper>
      <Tabels
        columns={columns}
        data={vacationRequests}
        cellRenderer={cellRenderer}
      />
    </Wrapper>
  )
}

export default EmployeeVacationHistory

const formatDate = (date: string): string => {
  return dayjs(date).format('YYYY-MM-DD')
}

const getVacationType = (key: string): string => {
  switch (key) {
    case 'ondemand':
      return t('CompanyHistory:History.ondemand')
    case 'recreational':
      return t('CompanyHistory:History.recreational')
    case 'occasional':
      return t('CompanyHistory:History.occasional')
    case 'parental':
      return t('CompanyHistory:History.parental')
    case 'unpaid':
      return t('CompanyHistory:History.unpaid')
    case 'L4':
      return t('CompanyHistory:History.l4')
    default:
      return t('CompanyHistory:History.default')
  }
}
