import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

export function useChangePasswordValidation() {
  const { t } = useTranslation()

  return Yup.object().shape({
    password: Yup.string()
      .required(t('Root:Validation.EnterOldPassword'))
      .min(6, t('Root:Validation.PasswordMinLength6')),

    new_password1: Yup.string()
      .required(t('Root:Validation.PasswordMinLength'))
      .min(8, t('Root:Validation.PasswordMinLength')),

    new_password2: Yup.string()
      .required(t('Root:Validation.Required'))
      .min(8, t('Root:Validation.PasswordMinLength')),
  })
}

const vacationRequestSchema = Yup.object().shape({
  starting_date: Yup.date().required('Starting date is required'),
  finishing_date: Yup.date()
    .required('Finishing date is required')
    .min(Yup.ref('starting_date'), 'Finishing date cannot be before the starting date'),
})

export default vacationRequestSchema
