import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  setCredentials,
  signOut,
  selectCurrentToken,
  selectExpiryTimestamp,
} from '../features/auth/authSlice'
import { FetchArgs } from '@reduxjs/toolkit/query/react'

import { appConfig } from '../config'

const baseQuery = fetchBaseQuery({
  baseUrl: appConfig.SERVER_URL,
  mode: 'cors',
  prepareHeaders: (headers, { getState }: any) => {
    const token = getState().auth.token
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryReauth = async (args: string | FetchArgs, api: any, extraOptions: object) => {
  if (selectCurrentToken(api.getState())) {
    const expiresAt = selectExpiryTimestamp(api.getState())
    const currentTime = new Date()
    let options = { timeZone: 'utc' }
    let utcTime = currentTime.toLocaleString('en-US', options)
    const currentUTCTime = new Date(utcTime)
    const expiresIn = expiresAt ? expiresAt - currentUTCTime.getTime() : -currentUTCTime.getTime()
    if (expiresIn <= 10 * 60 * 1000) {
      const refreshResult = await baseQuery(
        {
          url: '/users/refresh',
          method: 'POST',
        },
        api,
        extraOptions,
      )
      if (refreshResult?.data) {
        console.log(refreshResult)
        api.dispatch(setCredentials({ ...refreshResult.data }))
      } else {
        api.dispatch(signOut())
      }
    }
  }
  let result = await baseQuery(args, api, extraOptions)

  return result
}

export const apiSlice = createApi({
  baseQuery: baseQueryReauth,
  endpoints: (builder) => ({}),
})
