import { apiSlice } from './api'
import storage from 'redux-persist/lib/storage'
import { configureStore } from '@reduxjs/toolkit'
import authReducer from './features/auth/authSlice'
import { persistStore, persistReducer } from 'redux-persist'

const persistConfig = {
  key: 'auth',
  storage,
}

const persistedAuthReducer = persistReducer(persistConfig, authReducer)

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: persistedAuthReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: false,
})

export const persistor = persistStore(store)
