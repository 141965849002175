import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import axios from 'axios'
import useOwnUser from '../../../../hooks/useOwnUser'
import { useGetCompanyWideRequestsQuery } from '../../../../features/vacations/vacationsApiSlice'
import { ApproveIcon, DenyIcon, CancelIcon, Header, RaportButton } from './historyElements'
import RejectionDialog from './components/RejectDialog'
import ApprovalDialog from './components/ApprovalDialog'
import { IVacationRequest } from '@/pkg/requests'
import { t } from 'i18next'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../../../../features/auth/authSlice'
import Tables from './../../../root/components/Table'
import CancellationDialog from './components/ CancelDialog'

import { appConfig } from '../../../../config'

interface GroupedRequests {
  [key: string]: IVacationRequest[]
}

const CompanyHistory: React.FC = () => {
  const sessionToken = useSelector(selectCurrentToken)

  const columns = [
    { header: t('CompanyHistory:History.fullName'), accessor: 'full_name' },
    { header: t('CompanyHistory:History.type'), accessor: 'type' },
    { header: t('CompanyHistory:History.startingDate'), accessor: 'starting_date' },
    { header: t('CompanyHistory:History.finishingDate'), accessor: 'finishing_date' },
    { header: t('CompanyHistory:History.hours'), accessor: 'hours' },
    { header: t('CompanyHistory:History.createdAt'), accessor: 'created_at' },
    { header: t('CompanyHistory:History.status'), accessor: 'status' },
    { header: t('CompanyHistory:History.options'), accessor: 'options' },
  ]

  const user = useOwnUser()
  const {
    data: requests,
    isSuccess,
    refetch,
  } = useGetCompanyWideRequestsQuery(
    { companyID: user.selectedCompanyID },
    { skip: user.selectedCompanyID === undefined },
  )

  const [isCancellationDialogOpen, setIsCancellationDialogOpen] = useState<boolean>(false)
  const openCancellationDialog = (requestId: number, requestInfo: any) => {
    setIsCancellationDialogOpen(true)
    setSelectedRequestID(requestId)
    setInfo(requestInfo)
  }

  const [isRejectionDialogOpen, setIsRejectionDialogOpen] = useState<boolean>(false)
  const [selectedRequestID, setSelectedRequestID] = useState<number>(0)
  const [isApprovalDialogOpen, setIsApprovalDialogOpen] = useState<boolean>(false)
  const [info, setInfo] = useState<any>()
  const [sortKey, setSortKey] = useState<string | null>(null)
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
  const groupRequestsByMonth = (requests: IVacationRequest[]): GroupedRequests => {
    const grouped: GroupedRequests = {}
    requests.forEach((request) => {
      const month = dayjs(request.starting_date).format('MMMM YYYY')
      if (!grouped[month]) {
        grouped[month] = []
      }
      grouped[month].push(request)
    })
    return grouped
  }

  let groupedRequests = isSuccess && requests ? groupRequestsByMonth(requests) : {}

  const sortedMonths = Object.keys(groupedRequests).sort((a, b) => {
    const dateA = dayjs(a, 'MMMM YYYY')
    const dateB = dayjs(b, 'MMMM YYYY')

    if (dateA.year() !== dateB.year()) {
      return dateB.year() - dateA.year()
    }
    return dateB.month() - dateA.month()
  })

  const statusToColor = {
    pending: '#fcba03',
    approved: 'green',
    rejected: 'red',
    cancelled: 'blue',
  }

  const cellRenderer = (dataKey: string, row: any): JSX.Element => {
    const getColor = (status: string) =>
      statusToColor[status as keyof typeof statusToColor] || 'inherit'

    if (dataKey === 'starting_date' || dataKey === 'finishing_date' || dataKey === 'created_at') {
      return <span>{formatDate(row[dataKey])}</span>
    }
    if (dataKey === 'type') {
      return <span>{getVacationType(row[dataKey])}</span>
    }
    if (dataKey === 'status') {
      return (
        <span style={{ color: getColor(row.status) }}>
          {t('historyVacation:HistoryUser.' + row[dataKey])}
        </span>
      )
    }
    if (dataKey === 'options') {
      return (
        <div>
          <div style={{ display: 'flex' }}>
            {row.status === 'pending' && (
              <>
                <div
                  className='button_hover'
                  onClick={() => {
                    setIsApprovalDialogOpen(true)
                    setSelectedRequestID(row.id)
                    setInfo(row)
                  }}
                >
                  <ApproveIcon />
                </div>
                <div
                  className='button_hover'
                  onClick={() => {
                    setIsRejectionDialogOpen(true)
                    setSelectedRequestID(row.id)
                    setInfo(row)
                  }}
                >
                  <DenyIcon />
                </div>
              </>
            )}
            {row.status === 'approved' && (
              <div
                className='button_hover'
                onClick={() => {
                  openCancellationDialog(row.id, row)
                }}
              >
                <CancelIcon />
              </div>
            )}
          </div>

          <RejectionDialog
            isOpen={isRejectionDialogOpen}
            handleClose={() => setIsRejectionDialogOpen(!isRejectionDialogOpen)}
            request={info}
            refetch={refetch}
          />
          <ApprovalDialog
            isOpen={isApprovalDialogOpen}
            handleClose={() => setIsApprovalDialogOpen(!isApprovalDialogOpen)}
            request={info}
            refetch={refetch}
          />
          <CancellationDialog
            isOpen={isCancellationDialogOpen}
            handleClose={() => setIsCancellationDialogOpen(false)}
            request={info}
            refetch={refetch}
          />
        </div>
      )
    }

    return <span>{row[dataKey]}</span>
  }

  const handleGetPDF = (month: string): void => {
    // using axios so RTK does not cache files
    const date = new Date(`${month.split(' ')[0]} 1, 2000`)
    const monthNumber = date.getMonth() + 1
    axios
      .get(`${appConfig.SERVER_URL}/vacation/month-summary`, {
        params: {
          company_id: user.selectedCompanyID,
          month: monthNumber,
          year: month.split(' ')[1],
        },
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      })
      .then((res: any) => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `summary.pdf`)
        document.body.appendChild(link)
        link.click()
      })
  }

  return (
    <>
      {sortedMonths.map((month) => (
        <div key={month}>
          <Header>
            <h2>{t('Root:' + month.split(' ')[0]) + ' ' + month.split(' ')[1]}</h2>
            <span />
            <RaportButton onClick={() => handleGetPDF(month)}>
              {t('historyVacation:HistoryUser.getRaport')}
            </RaportButton>
          </Header>
          <tbody>
            <Tables
              columns={columns}
              data={groupedRequests[month]}
              cellRenderer={cellRenderer}
            />
          </tbody>
        </div>
      ))}
    </>
  )
}

export default CompanyHistory

const formatDate = (date: string): string => {
  return dayjs(date).format('YYYY-MM-DD')
}

const getVacationType = (key: string): string => {
  switch (key) {
    case 'ondemand':
      return t('CompanyHistory:History.ondemand')
    case 'recreational':
      return t('CompanyHistory:History.recreational')
    case 'occasional':
      return t('CompanyHistory:History.occasional')
    case 'parental':
      return t('CompanyHistory:History.parental')
    case 'unpaid':
      return t('CompanyHistory:History.unpaid')
    case 'L4':
      return t('CompanyHistory:History.l4')
    default:
      return t('CompanyHistory:History.default')
  }
}
