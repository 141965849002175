import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import RequireAuth from '../../features/auth/RequireAuth'

// layouts
import AuthLayout from './layouts/AuthLayout'
import MainLayout from './layouts/MainLayout'

//views
import SignInView from './views/SignInView'
import NotFound from './views/NotFound'
import RegisterViews from './views/RegisterView'

//modules
import JobMarket from '../jobMarket'
import VacationManager from '../vacationManager'

import ChangePassword from './views/Settings/changePassword'

const Root: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* views not needing layouts like 404 or unauthorised pages */}
        <Route path={'/404'} element={<NotFound />} />
        {/* auth layout views (everything happening without authorisation) */}
        <Route element={<AuthLayout />}>
          <Route path={'/'} element={<SignInView />} />

          <Route path={'/register'} element={<RegisterViews />} />
        </Route>

        {/* main layout modules (modules that require both authorisation & layout) */}
        <Route element={<MainLayout />}>
          <Route element={<RequireAuth />}>
            <Route path={'/vacation/*'} element={<VacationManager />} />
            <Route path={'/market/*'} element={<JobMarket />} />
            <Route path={'/settings/*'} element={<ChangePassword />} />
          </Route>
        </Route>

        <Route path="/signin" element={<Navigate to={'/'} replace />} />
        <Route path="/*" element={<Navigate to={'/404'} replace />} />
      </Routes>
    </Router>
  )
}

export default Root
