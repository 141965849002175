import * as React from 'react'
import { Wrapper, FormContainer } from './marketElements'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'


const Market: React.FC = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <FormContainer>
      <h1>{t('JobMarket:Header.header')} 🌴 </h1>
      </FormContainer>
    </Wrapper>
  )
}

export default Market
