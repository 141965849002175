import { styled, css } from 'styled-components'
import { Link } from 'react-router-dom'

export const SignInWrapper = styled.form`
  width: 35vw;
  height: 95vh;
  padding: 2rem;
  position: relative;
  color: ${(props) => props.theme.text.light};
  background-color: ${(props) => props.theme.background.white};
  border-radius: 30px;
  overflow-y: scroll;

  @media (max-width: 768px) {
    width: 70vw;
  }

  & > h1 {
    letter-spacing: 0.03rem;
    text-transform: uppercase;
    background: -webkit-linear-gradient(#b7a8b1, #d7a989);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const Input = css`
  display: flex;
  margin: 2rem 0;
  flex-direction: column;

  &:focus-within > label {
    color: ${(props) => props.theme.text.orange};
  }

  & > label {
    font-size: 1.1rem;
    letter-spacing: 0.02rem;
    transition: color 0.2s linear;
    color: ${(props) => props.theme.text.light};
  }

  & > input {
    border: none;
    padding: 0.3rem;
    font-size: 1.1rem;
    background-color: transparent;
    transition: border 0.2s linear;
    color: ${(props) => props.theme.text.light};
    border-bottom: 0.15rem solid ${(props) => props.theme.text.light};

    &:focus {
      border: none;
      outline: none;
      border-bottom: 0.15rem solid ${(props) => props.theme.text.orange};
    }
  }
`

export const UsernameInput = styled.div`
  ${Input}
`

export const PasswordInput = styled.div`
  ${Input}
`

export const EmailInput = styled.div`
  ${Input}
`

export const FirstnameInput = styled.div`
  ${Input}
`
export const LastnameInput = styled.div`
  ${Input}
`
export const PhoneInput = styled.div`
  ${Input}
`

export const SubmitButton = styled.button`
  width: 70%;
  border: none;
  padding: 1rem;
  cursor: pointer;
  font-weight: bolder;
  letter-spacing: 0.04rem;
  text-transform: uppercase;
  position: relative;
  margin-top: -50px;
  left: 57px;
  color: ${(props) => props.theme.background.white};
  background-color: #75adbd;
  border-radius: 30px;
  background-image: linear-gradient(to right, #b7a8b1 0%, #d7a989 51%, #c26f52 100%);
`

export const RegisterLink = styled(Link)`
  left: 2rem;
  bottom: 3rem;
  cursor: pointer;
  position: absolute;
  font-weight: lighter;
  text-decoration: none;
  letter-spacing: 0.02rem;
  color: ${(props) => props.theme.text.dark};
`
