import { apiSlice } from '../../api'
import * as Request from '../../pkg/requests'

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        signIn: builder.mutation({
            query: (credentials: Request.ISignIn) => ({
                url: '/auth/signin',
                method: 'POST',
                body: {...credentials}
            })
        }),
        getOwnUser: builder.query({
            query: () => '/auth/user/me',
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            }
        }),
        changePassword: builder.mutation({
            query: (request: Request.IChangePassword) => ({
                url: '/auth/user/change-password',
                method: 'PUT',
                body: {...request}
            })
        })
    })
})

export const {
    useSignInMutation,
    useGetOwnUserQuery,
    useChangePasswordMutation,
} = authApiSlice