import { styled, css } from 'styled-components'
import { FaList } from 'react-icons/fa'
import { MdArrowDropDown } from 'react-icons/md'
import { Link } from 'react-router-dom'

interface SideNavProps {
  isOpen: boolean
}

export const DropIcon = styled(MdArrowDropDown)`
  width: max-content;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 1.5rem;
  }
`

export const ListIcon = styled(FaList)`
  justify-content: center;
  align-items: center;
  align-content: center;

  @media (max-width: 768px) {
    width: 1.5rem;
  }
`
export const NavBarContainer = styled.nav`
  display: flex;
  z-index: 999;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  border-radius: 10px;
  margin-top: 2rem;
  background: ${(props) => props.theme.gradients.primary};
  color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  & a {
    text-decoration: none;
    color: #fff;
  }

  @media (max-width: 768px) {
    & > div,
    ul {
      display: none;
    }
  }
`
export const NavLogo = styled.div`
  font-weight: lighter;
  font-size: 2rem;
  position: relative;
  left: 20px;
`

export const NavItems = styled.ul`
  list-style-type: none;
  display: flex;
  gap: 30px;

  @media (max-width: 768px) {
    gap: 15px;
  }
`

export const NavItem = styled.li`
  cursor: pointer;
`
export const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`

export const Drop = styled.div`
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  & > div {
    cursor: pointer;
    color: ${(props) => props.theme.text.default};
    padding: 12px 16px;
    text-decoration: none;
    text-align: center;
    display: block;
    position: absolute;
    top: 100%;
    width: fit-content;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  }
`

export const NavLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 1.2rem;
  padding: 0.5rem;
  cursor: pointer;

  & p {
    cursor: pointer;
    color: ${(props) => props.theme.text.default};
    padding: 12px 16px;
    display: block;
    text-decoration: none;
    text-align: center;
    font-size: 1rem;
  }

  & p:hover {
    background-color: #f1f1f1;
  }
`
export const SideNav = styled.div<{ isOpen: boolean }>`
  height: 100%;
  width: ${(props) => (props.isOpen ? '250px' : '0')};
  position: fixed;
  z-index: 300;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;

  a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;

    &:hover {
      color: #f1f1f1;
    }
  }

  .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    width: ${(props) => (props.isOpen ? '200px' : '0')};
    padding-top: 30px;
  }
`

export const MainContent = styled.div<{ isOpen: boolean }>`
  transition: margin-left 0.5s;
  padding: 10px;
  margin-left: ${(props) => (props.isOpen ? '20px' : '0')};

  @media (max-width: 768px) {
    margin-left: ${(props) => (props.isOpen ? '220px' : '0')};
  }
`

export const AvatarWrapper = styled.div`
  position: relative;
  display: inline-block;

  & img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    position: relative;
    top: 8px;
    margin-right: 13px;
    cursor: pointer;
  }
`

export const Menu = styled.div<{ isOpen: boolean }>`
  position: absolute;
  background-color: #fff;
  z-index: 999;
  color: ${(props) => props.theme.text.default};
  width: max-content;
  top: 100%;
  right: 0;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(-20px)')};
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;

  & > p {
    cursor: pointer;
    color: ${(props) => props.theme.text.default};
    padding: 12px 16px;
    display: block;
  }
  & p:hover {
    background-color: #f1f1f1;
  }
`

export const DaysCount = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;

  & p {
    margin-right: 7px;
  }
`

export const Navbars = styled.nav`
  display: flex;
  justify-content: space-between;
  background-color: #333;
  color: white;
  padding: 1rem;
`

export const NavItemsz = styled.div`
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: #fff;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  position: relative;
  left: -10px;
  cursor: pointer;
  top: 15px;

  & a {
    color: #fff;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    position: relative;
    left: -10px;
    cursor: pointer;
  }
`

export const DropdownContents = styled.div<{ isOpen: boolean }>`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
  min-width: 160px;
  margin-top: 30px;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(-20px)')};
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;

  & a {
    cursor: pointer;
    color: ${(props) => props.theme.text.default};
    padding: 12px 16px;
    display: block;
    text-decoration: none;
    text-align: center;
  }

  & a:hover {
    background-color: #f1f1f1;
  }
`

export const Dropdowns = styled.div`
  &:hover ${DropdownContents} {
    display: block;
  }
`

// -- //
export const MobileNav = styled.section`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    display: none;
  }
`

interface MobileProps {
  isOpen: boolean
}

export const BurgerMenu = styled.div<MobileProps>`
  width: 40px;
  height: 40px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-evenly;

  & > span {
    display: block;
    height: 1.5px;
    width: 100%;
    transition: all 0.3s ease-in-out;
    background-color: ${(props) => props.theme.background.default};

    ${(props) =>
      props.isOpen &&
      css`
        opacity: 0;

        &:first-of-type {
          opacity: 1;
          transform: rotate(45deg) translateY(15px);
        }

        &:last-of-type {
          opacity: 1;
          transform: rotate(-45deg) translateY(-15px);
        }
      `}
  }
`

export const MobileDrawer = styled.section<MobileProps>`
  z-index: 998;
  height: 0;
  width: 90dvw;
  display: block;
  border-radius: 10px;
  position: absolute;
  transition: all 0.2s ease-in-out;
  background-color: ${(props) => props.theme.background.white};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  & > p {
    margin: 0.5rem auto;
    display: none;
    font-size: 1.2rem;
    width: fit-content;
    text-decoration: none;
    color: ${(props) => props.theme.text.default};
  }

  ${(props) =>
    props.isOpen &&
    css`
      top: 140px;
      height: 70dvh;
      padding: 1rem;

      & > p {
        display: block;
      }
    `}
`

export const MobileLink = styled(Link)<MobileProps>`
  margin: 0.5rem auto;
  display: none;
  font-size: 1.2rem;
  width: fit-content;
  text-decoration: none;
  color: ${(props) => props.theme.text.default};

  ${(props) =>
    props.isOpen &&
    css`
      display: block;
    `}
`
