import { styled, css } from 'styled-components'
import background from './../../../../static/images/background.svg'

export const Wrapper = styled.div`
  min-height: 90vh;
  width: 100%;
  background-size: cover;
  background-image: url(${background});
  overflow-y: hidden;
`

export const FormContainer = styled.div`
  & h1 {
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5rem;
    color: ${(props) => props.theme.button.light};
  }
`