import { styled } from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

export const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
`

export const Dialog = styled.div`
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 1001;
  width: 600px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TextFieldStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  & > input {
    width: 100%;
    padding: 0.7rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  & label {
    font-weight: lighter;
    margin-bottom: 7px;
  }
`

export const SelectStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  & > select {
    width: 100%;
    padding: 0.7rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  & label {
    font-weight: lighter;
    margin-bottom: 7px;
  }
`

export const SubmitButton = styled.button`
  width: 100%;
  padding: 0.7rem;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 15px;

  &:hover {
    background-color: #0056b3;
  }
`
