import { styled } from 'styled-components'

export const Wrapper = styled.main`
  width: 90dvw;

  & h2 {
    color: ${(props) => props.theme.button.blue};
    margin-left: 1rem;
    margin-bottom: 1.5rem;
  }
`
export const Container = styled.div`
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 1rem;
  font-family: sans-serif;
  width: 100%;

  & thead {
    background: ${(props) => props.theme.gradients.primary};
    color: #ffffff;
    text-align: left;
  }

  & tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  & tr:last-of-type {
    border-bottom: 2px solid ${(props) => props.theme.gradients.primary};
  }

  & td {
    vertical-align: baseline;
    padding: 15px 22px;
  }

  & th {
    padding: 15px 22px;
    cursor: pointer;
    &:first-of-type {
      border-radius: 10px 0 0 0;
    }

    &:last-of-type {
      border-radius: 0 10px 0 0;
    }
  }

  & > table {
    border-collapse: collapse;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.12);
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
    width: 100%;

    @media screen and (max-width: 768px) {
      display: block;
      max-width: 90dvw;
      overflow-x: scroll;
      border-radius: 10px;
    }
  }
`
