import { styled } from 'styled-components'

export const Wrapper = styled.main`
  width: 90dvw;
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.12);
  overflow-y: scroll;
  border-spacing: 0;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    display: block;
    max-width: 90dvw;
    overflow-x: scroll;
    border-radius: 10px;
  }

  & thead {
    background: ${(props) => props.theme.gradients.primary};
    color: #ffffff;
    text-align: left;
  }

  & tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  & tr:last-of-type {
    border-bottom: 2px solid ${(props) => props.theme.gradients.primary};
  }

  & td {
    vertical-align: baseline;
    padding: 15px 22px;

    & > div {
      display: flex;
    }

    .button_hover {
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      &:hover {
        background-color: #ebebeb;
      }
    }
  }

  & th {
    padding: 15px 22px;
    &:first-of-type {
      border-radius: 10px 0 0 0;
    }

    &:last-of-type {
      border-radius: 0 10px 0 0;
    }
  }
`

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

export const PopUp = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;

  height: fit-content;


  overflow-y: auto;

  & p {
    font-size: 1.3rem;
    text-align: center;
    margin-bottom: 1.5rem;
    color: ${(props) => props.theme.button.blue};

    & b {
      color: ${(props) => props.theme.text.green};
    }
  }

  & h2 {
    font-size: 2rem;
    text-align: center;
    font-weight: lighter;
    margin-bottom: 1.5rem;
    margin-top: 3rem;
    color: ${(props) => props.theme.button.blue};
  }
`
export const CloseButton = styled.button`
  padding: 10px 20px 10px 20px;
  border: none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  display: block;
  float: right;
  position: relative;
  justify-content: right;
  background-color: ${(props) => props.theme.button.blue};
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.button.hoverBlue};
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(2px);
  }
`
