import React, { useState, FormEvent } from 'react'
import dayjs from 'dayjs'
import { useRequestVacationMutation } from '../../../../../../features/vacations/vacationsApiSlice'
import {
  FormContainer,
  FormGroup,
  FormLabel,
  FormInput,
  FormSelect,
  FormTextarea,
  FormButton,
} from './vacationElements'
import * as Request from '../../../../../../pkg/requests'
import useOwnUser from '../../../../../../hooks/useOwnUser'
import Alert from '../../../../../root/components/Alert'
import { useTranslation } from 'react-i18next'

interface FormProps {
  refetch: () => void
  handleClose: () => void
}

const VacationRequestForm: React.FC<FormProps> = ({ refetch, handleClose }) => {
  const { t } = useTranslation()
  const [createRequest] = useRequestVacationMutation()
  const { selectedCompanyID } = useOwnUser()
  const [form, setForm] = useState<Request.IVacationRequest>({
    company_id: 0,
    starting_date: '',
    finishing_date: '',
    type: 'recreational',
    hours: 0,
    status: 'pending',
    additional_info: '',
  })

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target
    setForm((prevForm) => ({ ...prevForm, [name]: value }))
  }

  const [responseMessage, setResponseMessage] = useState('')
  const [showResponse, setShowResponse] = useState(false)
  const [responseColor, setResponseColor] = useState('grey')

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const updatedForm = {
      ...form,
      company_id: selectedCompanyID,
      starting_date:
        form.starting_date ? dayjs(form.starting_date).add(12, 'hour').toISOString() : '',
      finishing_date:
        form.finishing_date ? dayjs(form.finishing_date).add(12, 'hour').toISOString() : '',
    }

    try {
      await createRequest(updatedForm).unwrap()
      setResponseMessage(t('VacationRequest:Vacation.RequestSent'))
      setResponseColor('success') // Success color identifier
      setShowResponse(true)
      await refetch()
      handleClose()
    } catch (error) {
      console.error(error)
      setResponseMessage(t('VacationRequest:Vacation.RequestError'))
      setResponseColor('error') // Error color identifier
      setShowResponse(true)
    }
  }

  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <h2>{t('VacationRequest:Vacation.vacationForm')}</h2>
        <FormGroup>
          <FormLabel htmlFor='type'>{t('VacationRequest:Vacation.type')}</FormLabel>
          <FormSelect
            id='type'
            name='type'
            value={form.type}
            onChange={handleChange}
          >
            <option value='recreational'>{t('VacationRequest:Vacation.recreational')}</option>
            <option value='ondemand'>{t('VacationRequest:Vacation.ondemand')}</option>
            <option value='occasional'>{t('VacationRequest:Vacation.occasional')}</option>
            <option value='parental'>{t('VacationRequest:Vacation.parental')}</option>
            <option value='unpaid'>{t('VacationRequest:Vacation.unpaid')}</option>
            {/* display only for contract_type != 'contract_of_employment' */}
            <option value='l4'>{t('VacationRequest:Vacation.l4')}</option>
          </FormSelect>
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor='starting_date'>
            {t('VacationRequest:Vacation.startingDate')}
          </FormLabel>
          <FormInput
            type='date'
            id='starting_date'
            name='starting_date'
            value={form.starting_date}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor='finishing_date'>
            {t('VacationRequest:Vacation.finishingDate')}
          </FormLabel>
          <FormInput
            type='date'
            id='finishing_date'
            name='finishing_date'
            value={form.finishing_date}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor='hours'>{t('VacationRequest:Vacation.hours')}</FormLabel>
          <FormInput
            type='number'
            id='hours'
            name='hours'
            value={form.hours}
            onChange={(e: { target: { value: string } }) =>
              setForm({ ...form, hours: parseInt(e.target.value, 10) })
            }
          />
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor='additional_info'>
            {t('VacationRequest:Vacation.additionalInfo')}
          </FormLabel>
          <FormTextarea
            id='additional_info'
            name='additional_info'
            value={form.additional_info}
            onChange={handleChange}
          />
        </FormGroup>
        <FormButton type='submit'>{t('VacationRequest:Vacation.submit')}</FormButton>
      </form>

      <Alert
        message={responseMessage ?? ''}
        color={responseColor}
        show={showResponse}
        handleClose={() => setShowResponse(false)}
      />
    </FormContainer>
  )
}

export default VacationRequestForm
