import { apiSlice } from '../../api'
import { IEmployee } from '@pkg/responses'

export const companyApiSlice = apiSlice.injectEndpoints({
    
    endpoints: builder => ({
        getCompanyEmployees: builder.query<IEmployee[], { companyID: number }>({
            query: ({ companyID }) => `/company/employees?company_id=${companyID}`,
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            }
        }),
       
    })
})

export const {
    useGetCompanyEmployeesQuery,
} = companyApiSlice