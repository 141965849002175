import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { setCredentials } from '../../../../features/auth/authSlice'
import { useSignInMutation } from '../../../../features/auth/authApiSlice'

import * as Yup from 'yup'
import * as Request from '../../../../pkg/requests'

import {
  RegisterLink,
  SubmitButton,
  SignInWrapper,
  UsernameInput,
  PasswordInput,
  EmailInput,
  FirstnameInput,
  LastnameInput,
  PhoneInput,
} from './registerElements'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

const RegisterView: React.FC = () => {
  const { t } = useTranslation()
  const [signIn] = useSignInMutation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const signInValidationSchema: any = Yup.object().shape({
    username: Yup.string().required('Required'),
    password: Yup.string().min(8, 'Too short').required('Required'),
  })

  const methods = useForm<Request.IRegister>({
    resolver: yupResolver(signInValidationSchema),
  })
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = methods

  const onSubmit = async (credentials: Request.IRegister): Promise<any> => {
    try {
      const { username, first_name, last_name, password1, password2, email, phone } = credentials

      // const userData = await signIn({ username, first_name, last_name, password1, password2, email, phone, }).unwrap()
      // dispatch(setCredentials({ ...userData, username }))
      // navigate('/market')
    } catch (err: any) {
      if (err?.response?.status === 500) {
      } else {
      }
    }
  }

  return (
    <SignInWrapper onSubmit={handleSubmit(onSubmit)}>
      <h1>register</h1>
      <Controller
        control={control}
        name={'username'}
        render={({ field }) => (
          <UsernameInput>
            <label>Username</label>
            <input type={'text'} {...field} />
          </UsernameInput>
        )}
      />

      <Controller
        control={control}
        name={'first_name'}
        render={({ field }) => (
          <FirstnameInput>
            <label>Firstname</label>
            <input type={'text'} {...field} />
          </FirstnameInput>
        )}
      />

      <Controller
        control={control}
        name={'last_name'}
        render={({ field }) => (
          <LastnameInput>
            <label>Lastname</label>
            <input type={'text'} {...field} />
          </LastnameInput>
        )}
      />

      <Controller
        control={control}
        name={'email'}
        render={({ field }) => (
          <EmailInput>
            <label>Email</label>
            <input type={'email'} {...field} />
          </EmailInput>
        )}
      />

      <Controller
        control={control}
        name={'phone'}
        render={({ field }) => (
          <PhoneInput>
            <label>Phone</label>
            <input type={'text'} {...field} />
          </PhoneInput>
        )}
      />

      <Controller
        control={control}
        name={'password1'}
        render={({ field }) => (
          <PasswordInput>
            <label>Password</label>
            <input type={'password'} {...field} />
          </PasswordInput>
        )}
      />

      <Controller
        control={control}
        name={'password2'}
        render={({ field }) => (
          <PasswordInput>
            <label>Repeat Password</label>
            <input type={'password'} {...field} />
          </PasswordInput>
        )}
      />

      <SubmitButton type={'submit'}>sign up</SubmitButton>
      <RegisterLink to={'/register'}></RegisterLink>
    </SignInWrapper>
  )
}

export default RegisterView
