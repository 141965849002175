import React, { useState, useEffect } from 'react'
import {
  NavBarContainer,
  NavLogo,
  NavItems,
  DropIcon,
  DropdownItem,
  Drop,
  NavLink,
  AvatarWrapper,
  Menu,
  NavItemsz,
  Dropdowns,
  BurgerMenu,
  DropdownContents,
  MobileNav,
  MobileDrawer,
  MobileLink,
} from './navElements'
import useOwnUser from '../../../../hooks/useOwnUser'
import * as Response from '../../../../pkg/responses'
import user from '../../../../static/images/user.png'
import RequireRole from '../../../../features/auth/RequireRole'
import { useDispatch } from 'react-redux'
import { signOut } from '../../../../features/auth/authSlice'
import { apiSlice } from '../../../../api/index'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

interface AvatarCircleProps {
  imageUrl: string
}
const AvatarCircle: React.FC<AvatarCircleProps> = ({ imageUrl }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false)
  const handleMouseEnter = () => setIsUserMenuOpen(true)
  const handleMouseLeave = () => setIsUserMenuOpen(false)
  return (
    <AvatarWrapper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img
        src={imageUrl}
        alt='Avatar'
      />
      {isUserMenuOpen && (
        <Menu isOpen={isUserMenuOpen}>
          <NavLink to='/settings'>
            <p>{t('Root:Nav.ChangePassword')}</p>
          </NavLink>
          <p onClick={() => dispatch(signOut())}>{t('Root:Nav.Logout')}</p>
        </Menu>
      )}
    </AvatarWrapper>
  )
}

const SideNavComponent: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  const openNav = () => {
    setIsOpen(true)
  }
  const closeNav = () => {
    setIsOpen(false)
    document.body.style.backgroundColor = 'white'
  }
  return <></>
}
interface DropdownProps {
  children: React.ReactNode
  selectedNode: Response.CompanyProfile
}

export const Dropdown: React.FC<DropdownProps> = ({ children, selectedNode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <Drop
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <h3>{selectedNode.name}</h3>
      <DropIcon />
      {isOpen && <div>{children}</div>}
    </Drop>
  )
}

const NavbarComponent: React.FC = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen)
  return (
    <Dropdowns onClick={toggleDropdown}>
      <RequireRole allowedRoles={['owner', 'manager', 'employee']}>
        <NavItemsz>{t('Root:Nav.Vacations')}</NavItemsz>
      </RequireRole>
      <DropdownContents isOpen={isDropdownOpen}>
        <RequireRole allowedRoles={['employee', 'manager', 'owner']}>
          <a href='/vacation'>{t('Root:Nav.MyVacations')}</a>
        </RequireRole>
        <RequireRole allowedRoles={['owner', 'manager']}>
          <a href='/vacation/company-requests'>{t('Root:Nav.Vacations')}</a>
        </RequireRole>
      </DropdownContents>
    </Dropdowns>
  )
}

const Nav = (): JSX.Element => {
  const dispatch = useDispatch()

  const { companyProfiles, selectedCompanyID, setSelectedCompanyID, setGroups } = useOwnUser()
  const [selected, setSelected] = useState<Response.CompanyProfile | undefined>(
    selectedCompanyID && companyProfiles ?
      companyProfiles.find((profile) => profile.id === selectedCompanyID)
    : undefined,
  )

  const handleProfileChange = (profile: Response.CompanyProfile): void => {
    setSelected(profile)
    setSelectedCompanyID(profile.id)
    setGroups(profile.groups)
    dispatch(apiSlice.util.resetApiState())
  }

  useEffect(() => {
    if (companyProfiles && !selected) {
      const defaultProfile =
        companyProfiles.find((profile) => profile.id === selectedCompanyID) || companyProfiles[0]
      if (defaultProfile) {
        handleProfileChange(defaultProfile)
      }
    }
  }, [companyProfiles, selectedCompanyID])

  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState<boolean>(false)

  if (!selected) return <></>

  return (
    <>
      <NavBarContainer>
        <div>
          <SideNavComponent />
          <a href='/market'>
            <NavLogo> WHARF</NavLogo>
          </a>
        </div>
        <NavItems>
          <NavbarComponent />
          <RequireRole allowedRoles={['owner', 'manager']}>
            <NavLink to='/vacation/employees'>{t('Root:Nav.Employees')}</NavLink>
          </RequireRole>
          <Dropdown selectedNode={selected}>
            {companyProfiles &&
              companyProfiles.map((profile: Response.CompanyProfile) => (
                <DropdownItem
                  key={profile.id}
                  onClick={() => handleProfileChange(profile)}
                >
                  {profile.name}
                </DropdownItem>
              ))}
          </Dropdown>
          <AvatarCircle imageUrl={user}></AvatarCircle>
        </NavItems>
        <MobileNav>
          <BurgerMenu
            isOpen={isMobileDrawerOpen}
            onClick={() => setIsMobileDrawerOpen((prev) => !prev)}
          >
            <span />
            <span />
            <span />
          </BurgerMenu>
          <AvatarCircle imageUrl={user} />
        </MobileNav>
      </NavBarContainer>
      <MobileDrawer isOpen={isMobileDrawerOpen}>
        <MobileLink
          isOpen={isMobileDrawerOpen}
          to='/market'
          onClick={() => setIsMobileDrawerOpen((prev) => !prev)}
        >
          {t('Root:Nav.Home')}
        </MobileLink>
        <RequireRole allowedRoles={['owner', 'manager']}>
          <MobileLink
            isOpen={isMobileDrawerOpen}
            to='/vacation/employees'
            onClick={() => setIsMobileDrawerOpen((prev) => !prev)}
          >
            {t('Root:Nav.Employees')}
          </MobileLink>
        </RequireRole>
        <RequireRole allowedRoles={['employee', 'manager', 'owner']}>
          <MobileLink
            isOpen={isMobileDrawerOpen}
            onClick={() => setIsMobileDrawerOpen((prev) => !prev)}
            to='/vacation'
          >
            {t('Root:Nav.MyVacations')}
          </MobileLink>
        </RequireRole>
        <RequireRole allowedRoles={['owner', 'manager']}>
          <MobileLink
            isOpen={isMobileDrawerOpen}
            onClick={() => setIsMobileDrawerOpen((prev) => !prev)}
            to='/vacation/company-requests'
          >
            {t('Root:Nav.Vacations')}
          </MobileLink>
        </RequireRole>
        {companyProfiles &&
          companyProfiles.map((profile: Response.CompanyProfile) => (
            <p
              onClick={() => {
                handleProfileChange(profile)
                setIsMobileDrawerOpen((prev) => !prev)
              }}
            >
              {profile.name}
            </p>
          ))}
      </MobileDrawer>
    </>
  )
}

export default Nav
