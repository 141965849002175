import { useState, useEffect, createContext } from 'react'
import * as Response from '../../src/pkg/responses'

interface IUserContext {
    userUID: number | null,
    avatarLink: string | null,
    companyProfiles: Response.CompanyProfile[] | null,
    selectedCompanyID: number,
    groups: string | undefined,
    setUserUID: (arg: number) => void,
    setAvatarLink: (arg: string) => void,
    setCompanyProfiles: (arg: Response.CompanyProfile[]) => void,
    setSelectedCompanyID: (companyID: number) => void
    setGroups: (groups: string) => void
}

const defaultUserContext: IUserContext = {
    userUID: null,
    avatarLink: null,
    companyProfiles: null,
    selectedCompanyID: 0,
    groups: undefined,
    setUserUID: () => { },
    setAvatarLink: () => { },
    setCompanyProfiles: () => { },
    setSelectedCompanyID: () => { },
    setGroups: () => { },
}

const UserContext = createContext<IUserContext>(defaultUserContext)

export const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [userUID, setUserUID] = useState<number | null>(
        () => localStorage.getItem('userUID') ? Number(localStorage.getItem('userUID')) : null
    )
    const [avatarLink, setAvatarLink] = useState<string | null>(
        () => localStorage.getItem('avatarLink') || null
    )
    const [companyProfiles, setCompanyProfiles] = useState<Response.CompanyProfile[] | null>(
        () => JSON.parse(localStorage.getItem('companyProfiles') || 'null')
    )
    const [selectedCompanyID, setSelectedCompanyID] = useState<number>(
        () => Number(localStorage.getItem('selectedCompanyID')) || 0
    )
    const [groups, setGroups] = useState<string | undefined>(
        () => localStorage.getItem('groups') || undefined
    )

    useEffect(() => {
        localStorage.setItem('userUID', userUID?.toString() || '')
    }, [userUID])

    useEffect(() => {
        localStorage.setItem('avatarLink', avatarLink || '')
    }, [avatarLink])

    useEffect(() => {
        localStorage.setItem('companyProfiles', JSON.stringify(companyProfiles))
    }, [companyProfiles])

    useEffect(() => {
        localStorage.setItem('selectedCompanyID', selectedCompanyID.toString())
    }, [selectedCompanyID])

    useEffect(() => {
        localStorage.setItem('groups', groups || '')
    }, [groups])

    return (
        <UserContext.Provider value={{
            userUID,
            avatarLink,
            companyProfiles,
            selectedCompanyID,
            groups,
            setUserUID,
            setAvatarLink,
            setCompanyProfiles,
            setSelectedCompanyID,
            setGroups,
        }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContext