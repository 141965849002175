import * as React from 'react'
import dayjs from 'dayjs'
import { Form, Wrapper, BackGround } from './cancelElements'
import useOwnUser from '../../../../../../hooks/useOwnUser'
import { useUpdateVacationMutation } from '../../../../../../features/vacations/vacationsApiSlice'
import { ICancelationProps } from '../../../../../../pkg/UI'
import { t } from 'i18next'

const CancelDialog = ({ ...props }: ICancelationProps): JSX.Element => {
  const user = useOwnUser()
  const [cancelRequest] = useUpdateVacationMutation()

  const handleCancel = (e: any): void => {
    e.preventDefault()
    let data = { ...props.request }
    data.status = 'cancelled'
    data.decision_maker_id = user.userUID as number
    data.decision_date = dayjs(new Date()).toISOString()
    cancelRequest(data)
      .then((res: any) => {
        
      })
      .then(() => props.refetch())
      .then(() => props.handleClose())
  }
  

  if (!props.isOpen) return <></>

  return (
    <Wrapper>
      <BackGround />
      <Form>
        <h1>{t('CompanyHistory:Cancel.cancelVacation')}</h1>
        <div className="spacer" />
        <div>
          <button onClick={(e) => handleCancel(e)}>{t('CompanyHistory:Cancel.cancelVacation')}</button>
          <button
            className="cancel"
            onClick={(e) => {
              e.preventDefault()
              props.handleClose()
            }}
          >
            {t('CompanyHistory:Cancel.cancel')}
          </button>
        </div>
      </Form>
    </Wrapper>
  )
}

export default CancelDialog
