import { useSelector } from 'react-redux'
import { useLocation, Navigate, Outlet } from 'react-router'
import { selectCurrentToken } from './authSlice'
import useOwnUser from '../../hooks/useOwnUser'

interface RequireAuthProps {
    allowedRoles?: string[]
}

const RequireAuth = ({ allowedRoles }: RequireAuthProps) => {
    const token: string | null = useSelector(selectCurrentToken)
    const {
        groups: role
    } = useOwnUser()
    const location = useLocation()

    if (!token) return <Navigate to='/signin' state={{ from: location }} replace />

    if (!allowedRoles || allowedRoles?.includes(role?? '') || role === 'admin' || role === 'dev') return <Outlet />

    return <Navigate to='/signin' state={{ from: location }} replace /> // Unauthorized
}

export default RequireAuth