import * as React from 'react'
import dayjs from 'dayjs'
import { Wrapper, BackGround, Form, InfoBox } from './approvalElements'
import useOwnUser from '../../../../../../hooks/useOwnUser'
import { useUpdateVacationMutation } from '../../../../../../features/vacations/vacationsApiSlice'
import { IApprovalRejectionProps } from '../../../../../../pkg/UI'
import { useTranslation } from 'react-i18next'

const ApprovalDialog = ({ ...props }: IApprovalRejectionProps): JSX.Element => {
  const { t } = useTranslation()
  const user = useOwnUser()
  const [updateRequest] = useUpdateVacationMutation()

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>,
  ): void => {
    e.preventDefault()
    let data = { ...props.request }
    data.status = 'approved'
    data.decision_maker_id = user.userUID as number
    data.decision_date = dayjs(new Date()).toISOString()

    updateRequest(data)
      .then((res: any) => {
        console.log(res)
        props.refetch()
        props.handleClose()
      })
      .catch((error: any) => {
        if (error.status === 500) {
          alert(t('CompanyHistory:Approval.serverError'))
        } else {
          console.error(error)
        }
      })
  }

  if (!props.isOpen) return <></>

  return (
    <Wrapper>
      <BackGround />
      <Form onSubmit={handleSubmit}>
        <h1>{t('CompanyHistory:Approval.acceptVacation')}</h1>
        {props.request.additional_info !== '' && (
          <InfoBox>
            <h4>{t('CompanyHistory:Approval.info')}</h4>
            <p>{props.request.additional_info}</p>
            
          </InfoBox>
        )}
        <div className='spacer' />
        <div>
          <button
            type='submit'
            className='submit'
          >
            {t('CompanyHistory:Approval.accept')}
          </button>
          <button
            type='button'
            className='cancel'
            onClick={(e) => {
              e.preventDefault()
              props.handleClose()
            }}
          >
            {t('CompanyHistory:Approval.cancel')}
          </button>
        </div>
      </Form>
    </Wrapper>
  )
}

export default ApprovalDialog
