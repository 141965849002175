import { Wrapper, FoundWrapper } from './notFoundElements'

import notfound from '../../../../static/images/notfound.jpg'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

interface FoundProps {
  imageUrl: string
}

const Found: React.FC<FoundProps> = ({ imageUrl }) => {
  const { t } = useTranslation()
  return (
    <FoundWrapper>
      <img src={imageUrl} />
    </FoundWrapper>
  )
}
const NotFound: React.FC = () => {
  return (
    <Wrapper>
      <Found imageUrl={notfound}></Found>
      <h1>{t('Root:Errors.PageNotFoundTitle')}</h1>
      <p>{t('Root:Errors.PageNotFoundMessage')}</p>
      <a href="/">{t('Root:Errors.Back')}</a>
    </Wrapper>
  )
}

export default NotFound
