import { Route, Routes } from 'react-router-dom'
import RequireAuth from '../../features/auth/RequireAuth'

//views
import HistoryVacation from './views/historyVacation'
import CompanyHistory from './views/companyHistory'
import EmployeesView from './views/employeesView'

const VacationManager: React.FC = () => {
  return (
    <Routes>
      <Route path={'/'} element={<HistoryVacation />} />
      <Route element={<RequireAuth allowedRoles={['owner', 'manager']} />}>
        <Route path={'/company-requests'} element={<CompanyHistory />} />
        <Route path={'/employees'} element={<EmployeesView />} />
      </Route>
    </Routes>
  )
}

export default VacationManager
