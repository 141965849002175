import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { setCredentials } from '../../../../features/auth/authSlice'
import { useGetOwnUserQuery, useSignInMutation } from '../../../../features/auth/authApiSlice'

import * as Yup from 'yup'
import * as Request from '../../../../pkg/requests'
import useOwnUser from '../../../../hooks/useOwnUser'
import useServerResponse from 'hooks/useServerResponse'
import { useTranslation } from 'react-i18next'

import {SubmitButton, SignInWrapper, UsernameInput, PasswordInput } from './signInElements'

const SignInView: React.FC = () => {
  const { t } = useTranslation()
  const [signIn] = useSignInMutation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const signedUser = useOwnUser()
  const [isSigned, setIsSigned] = useState<boolean>(false)
  const ownUser = useGetOwnUserQuery({}, { skip: !isSigned })

  useEffect(() => {
    if (ownUser.data) {
      signedUser.setUserUID(ownUser.data.id)
      signedUser.setCompanyProfiles(ownUser.data.company_user_profiles)
      navigate('/market')
    }
  }, [ownUser.data])

  const { setResponseMessage, setShowResponse } = useServerResponse()

  const signInValidationSchema: any = Yup.object().shape({
    username: Yup.string().required('Required'),
    password: Yup.string().min(4, 'Too short').required('Required'),
  })

  const methods = useForm<Request.ISignIn>({
    resolver: yupResolver(signInValidationSchema),
  })
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = methods

  const onSubmit = async (credentials: Request.ISignIn): Promise<void> => {
    try {
      const { username, password } = credentials

      const userData = await signIn({ username, password }).unwrap()
      dispatch(setCredentials({ ...userData, username }))
      setIsSigned(true)
    } catch (err: any) {
      if (err?.response?.status === 500) {
        setResponseMessage(t('Root:Login.ServerError'))
        setShowResponse(true)
      } else {
        setResponseMessage(t('Root:Login.InvalidLogin'))
        setShowResponse(true)
      }
    }
  }

  return (
    <SignInWrapper onSubmit={handleSubmit(onSubmit)}>
      <h1>{t('Root:Login.Login')}</h1>
      <Controller
        control={control}
        name={'username'}
        render={({ field }) => (
          <UsernameInput>
            <label>{t('Root:Login.Username')}</label>
            <input type={'text'} {...field} />
          </UsernameInput>
        )}
      />
      <Controller
        control={control}
        name={'password'}
        render={({ field }) => (
          <PasswordInput>
          <label>{t('Root:Login.Password')}</label>
            <input type={'password'} {...field} />
          </PasswordInput>
        )}
      />
      <SubmitButton type="submit">{t('Root:Login.LoginButton')}</SubmitButton>
    </SignInWrapper>
  )
}

export default SignInView
