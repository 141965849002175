import { styled, css } from 'styled-components'
import { Link } from 'react-router-dom'

export const SignInWrapper = styled.form`
  width: 100%;
  max-width: 500px;
  margin: 2.5rem;
  padding: 2.5rem;
  position: relative;
  color: ${(props) => props.theme.text.light};
  background: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  & > h1 {
    font-size: 2rem;
    letter-spacing: 0.03rem;
    text-transform: uppercase;
    background: ${(props) => props.theme.gradients.primary};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-bottom: 1rem;
  }
`

export const Input = css`
  display: block;
  margin: 1rem 0;
  padding: 0.9rem;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);

  @media (max-width: 768px) {
    width: 85%;
  }

  & button {
    height: 48px;
    width: 100%;
    color: ${(props) => props.theme.background.white};
    font-size: 16px;
    font-weight: 700;
    border-radius: 20px;
    margin-top: 1rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;

    @media (max-width: 768px) {
      padding: 0.5rem;
      margin: 0.5rem;
    }

    &:hover {
      background-color: ${(props) => props.theme.button.hoverBlue};
    }
  }

  & input,
  & select {
    width: 100%;
    padding: 12px;
    border: 1px solid ${(props) => props.theme.text.light};
    border-radius: 10px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
  }
`

export const UsernameInput = styled.div`
  ${Input}
`

export const PasswordInput = styled.div`
  ${Input}
`

export const SubmitButton = styled.button`
  width: 60%;
  border: none;
  padding: 1rem;
  cursor: pointer;
  font-weight: bolder;
  letter-spacing: 0.04rem;
  text-transform: uppercase;
  margin-top: 0.5rem;
  margin: 0 auto;
  display: block;
  color: ${(props) => props.theme.background.white};
  border-radius: 15px;
  background: ${(props) => props.theme.gradients.primary};
`
