import { apiSlice } from '../../api'
import * as Request from '../../pkg/requests'

export const companyProfilesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        updateCompanyProfile: builder.mutation({
            query: (data: Request.ICompanyProfileUpdateRequest) => ({
                url: '/company/update-profile',
                method: 'PUT',
                body: {...data}
            })
        }),
    })
})

export const {
    useUpdateCompanyProfileMutation,
} = companyProfilesApiSlice