import React, { useState, useEffect } from 'react'
import { Container, PopUp, PopUpContent, Pop, Coc, DaysCount, Wrap } from './historyElements'
import dayjs from 'dayjs'
import VacationRequestForm from './components/VacationRequest'
import { useGetUserVacationRequestsQuery } from '../../../../features/vacations/vacationsApiSlice'
import useOwnUser from 'hooks/useOwnUser'
import * as Response from '../../../../pkg/responses'
import { useTranslation } from 'react-i18next'
import Tables from '../../../root/components/Table'

interface SelectedType {
  name: string
  available_vacation_days?: number
}

interface ComponentProps {
  isFormVisible: boolean
  closeForm: () => void
  refetch: () => void
}

const MyComponent: React.FC<ComponentProps> = ({ isFormVisible, closeForm, refetch }) => {
  const { t } = useTranslation()
  return (
    <Pop>
      {isFormVisible && (
        <PopUp>
          <PopUpContent>
            <button onClick={closeForm}>{t('historyVacation:HistoryUser.close')}</button>
            <VacationRequestForm
              handleClose={closeForm}
              refetch={refetch}
            />
          </PopUpContent>
        </PopUp>
      )}
    </Pop>
  )
}

const formatDate = (date: string): string => {
  return dayjs(date).format('YYYY-MM-DD')
}

const HistoryVacation: React.FC = () => {
  const { t } = useTranslation()
  const columns = [
    { header: t('CompanyHistory:History.type'), accessor: 'type' },
    { header: t('CompanyHistory:History.startingDate'), accessor: 'starting_date' },
    { header: t('CompanyHistory:History.finishingDate'), accessor: 'finishing_date' },
    { header: t('CompanyHistory:History.hours'), accessor: 'hours' },
    { header: t('CompanyHistory:History.createdAt'), accessor: 'created_at' },
    { header: t('CompanyHistory:History.status'), accessor: 'status' },
  ]

  const user = useOwnUser()
  const [isFormVisible, setIsFormVisible] = useState(false)
  const {
    data: requests,
    isSuccess,
    refetch,
  } = useGetUserVacationRequestsQuery(
    { userID: Number(user.userUID), companyID: user.selectedCompanyID },
    { skip: !user },
  )

  const handleOpenForm = () => {
    setIsFormVisible(true)
  }

  const handleCloseForm = () => {
    setIsFormVisible(false)
  }

  const [isVacationsDropdownOpen, setIsVacationsDropdownOpen] = useState(false)

  const { companyProfiles, setSelectedCompanyID, setGroups, selectedCompanyID } = useOwnUser()
  const profile = companyProfiles?.find((profile) => profile.id === selectedCompanyID)
  const [selected, setSelected] = useState<Response.CompanyProfile | undefined>(profile)

  const handleProfileChange = (profile: Response.CompanyProfile): void => {
    setSelected(profile)
    setSelectedCompanyID(profile.id)
    setGroups(profile.groups)
  }

  useEffect(() => {
    if (companyProfiles && !selected) {
      handleProfileChange(companyProfiles[0])
    }
  }, [companyProfiles, selected, handleProfileChange])

  if (!selected) return <></>

  const statusToColor = {
    pending: '#fcba03',
    approved: 'green',
    rejected: 'red',
    cancelled: 'blue',
  }

  const cellRenderer = (dataKey: string, row: any): JSX.Element => {
    const getColor = (status: string) =>
      statusToColor[status as keyof typeof statusToColor] || 'inherit'

    if (dataKey === 'starting_date' || dataKey === 'finishing_date' || dataKey === 'created_at') {
      return <span>{formatDate(row[dataKey])}</span>
    }
    if (dataKey === 'type') {
      return <span>{getVacationType(row[dataKey], t)}</span>
    }
    if (dataKey === 'status') {
      return (
        <span style={{ color: getColor(row.status) }}>
          {t('historyVacation:HistoryUser.' + row[dataKey])}
        </span>
      )
    }
    return <span>{row[dataKey]}</span>
  }

  return (
    <>
      <Wrap>
        <Coc>
          <button onClick={handleOpenForm}>
            {t('historyVacation:HistoryUser.newApplication')}
          </button>
          <MyComponent
            isFormVisible={isFormVisible}
            closeForm={handleCloseForm}
            refetch={refetch}
          />
          <DaysCount>
            <>
              <p>{t('historyVacation:HistoryUser.vacationDays')}</p>
              {profile && profile.available_vacation_days}
            </>
          </DaysCount>
        </Coc>
        <Container>
          <tbody>
            {requests && requests.length > 0 && (
              <Tables
                data={requests}
                columns={columns}
                cellRenderer={cellRenderer}
              />
            )}
          </tbody>
        </Container>
      </Wrap>
    </>
  )
}

export default HistoryVacation

const getVacationType = (key: string, t: (key: string) => string): string => {
  switch (key) {
    case 'ondemand':
      return t('CompanyHistory:History.ondemand')
    case 'recreational':
      return t('CompanyHistory:History.recreational')
    case 'occasional':
      return t('CompanyHistory:History.occasional')
    case 'parental':
      return t('CompanyHistory:History.parental')
    case 'unpaid':
      return t('CompanyHistory:History.unpaid')
    case 'L4':
      return t('CompanyHistory:History.l4')
    default:
      return t('CompanyHistory:History.default')
  }
}
