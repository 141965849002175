export const sortDataSet = <T>(dataSet: T[], sortingKey: string, sortDirection: number): T[] => {
    // front-end sort
    if (!dataSet || dataSet.length === 0) return []
    if (sortDirection === 0) {
        return dataSet.slice()
    }

    const sortedData = dataSet.slice().sort((a, b) => {
        const valueA = (a as any)[sortingKey]
        const valueB = (b as any)[sortingKey]

        if (valueA < valueB) {
            return (sortDirection === 1) ? -1 : 1
        } else if (valueA > valueB) {
            return (sortDirection === 1) ? 1 : -1
        } else {
            return 0
        }
    })

    return sortedData
}
