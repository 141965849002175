import styled, { css } from 'styled-components'
import { FaCheck } from 'react-icons/fa6'
import { FaX } from 'react-icons/fa6'
import { TbPencilCancel } from 'react-icons/tb'

const IconButton = css`
  width: 1.3rem;
  height: 1.3rem;
  margin: 0 0.5rem;
  cursor: pointer;

  & path {
    fill: ${(props) => props.theme.text.main};
  }

  @media (max-width: 768px) {
    width: 2rem;
    height: 2rem;
    margin: 0 0.75rem;
  }
`

export const ApproveIcon = styled(FaCheck)`
  ${IconButton}
`

export const DenyIcon = styled(FaX)`
  ${IconButton}
`
export const CancelIcon = styled(TbPencilCancel)`
  ${IconButton}
`

export const RaportButton = styled.button`
  padding: 15px 25px 15px 25px;
  border: none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  background-color: ${(props) => props.theme.button.blue};
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.button.hoverBlue};
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(2px);
  }
`

export const Header = styled.div`
  display: flex;

  & > span {
    flex: 1;
  }
`
