import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'

//views
import Market from './views/Market'

const JobMarket: React.FC = () => {
  return (
    <Routes>
      <Route
        path={'/'}
        element={<Market />}
      />
    </Routes>
  )
}

export default JobMarket
