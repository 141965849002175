import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as Response from '@pkg/responses'

export interface ICredentialsState {
  username: string | null
  token: string | null
  companyProfiles: Response.CompanyProfile[] | null
  tokenExpiry: number | null
  selectedProfile: Response.CompanyProfile | null
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    username: null,
    token: null,
    tokenExpiry: new Date().getTime(),
    companyProfiles: [],
    selectedProfile: null
  },
  reducers: {
    setCredentials: (state, action) => {
      const { token, username, companyProfiles } = action.payload
      const currentTime = new Date()
      let options = { timeZone: 'utc' }
      let utcTime = currentTime.toLocaleString('en-US', options)
      const currentUTCTime = new Date(utcTime)
      state.token = token
      state.username = username
      state.companyProfiles = companyProfiles
      state.tokenExpiry = currentUTCTime.getTime() + 60 * 60 * 1000
    },
    signOut: (state) => {
      state.token = null
      state.token = null
      state.username = null
      state.companyProfiles = []
    },
    setSelectedProfile: (state, action) => {
      const profile = action.payload
  
      state.selectedProfile = profile
    }
  },
})

export const { setCredentials, signOut, setSelectedProfile } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state: { auth: ICredentialsState }) => state.auth.username
export const selectCurrentToken = (state: { auth: ICredentialsState }) => state.auth.token
export const selectExpiryTimestamp = (state: { auth: ICredentialsState }) =>
  state.auth.tokenExpiry ? state.auth.tokenExpiry : null
export const selectCurrentCompanyProfiles = (state: { auth: ICredentialsState }) =>
  state.auth.companyProfiles
export const selectSelectedProfile = (state: { auth: ICredentialsState }) => state.auth.selectedProfile
