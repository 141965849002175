import { styled, css } from 'styled-components'

export const Wrapper = styled.div`
  padding: 16px;
  border-radius: 4px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  font-family: Arial, sans-serif;
  max-width: 400px;
  position: fixed;
  top: 20px; /* Adjust position as needed */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
`
export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;

  &:hover {
    color: #ccc;
  }
`
