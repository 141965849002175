import { ThemeProvider } from 'styled-components'

import { theme } from './static/theme'

import Root from './app/root'

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Root />
    </ThemeProvider>
  )
}

export default App
