import { styled } from 'styled-components'
import backgroundLogin from '../../../../static/images/vac13.jpg'

export const AuthLayoutWrapper = styled.main`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image:  url(${backgroundLogin});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    
    `

export const CopyRight = styled.div`
    left: 22px;
    bottom: 0;
    padding: .5rem;
    position: fixed;
    color: ${props => props.theme.background.default};

    & > a {
        color: inherit;
        text-decoration: none;
    }
`
