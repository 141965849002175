import { styled, css } from 'styled-components'

export const Wrapper = styled.main`
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

export const Form = styled.form`
  background-color: ${(props) => props.theme.button.blue};
  border-radius: 0.5rem;
  padding: 2rem;
  opacity: 1;
  z-index: 1200;
  display: flex;
  flex-direction: column;
  height: 500px;
  width: 500px;
  overflow: hidden;
  position: relative;
  color: #fff;


  & .cancel {
    background-color: ${(props) => props.theme.text.dark};
    color: #fff;
  }

  & h1 {
    font-size: 2.5rem;
    text-align: center;
    font-weight: 400;
    color: #fff;
  }

  & span{
    flex: 1;
  }
  
  & .btn {
    padding: 15px;
    display: flex;
    justify-content: flex-end;
  }

  & button {
    background-color: ${(props) => props.theme.primary.darker};
    border-radius: 10px;
    border: none;
    font-size: 1rem;
    margin-left: 10px;
    margin-top: 10px;
    padding: 10px;
    color: #fff;
    cursor: pointer;
  }
`
export const BackGround = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;
`

export const AreaForm = styled.div`
  & label {
    font-size: 1.3rem;
    font-weight: lighter;
    position: relative;
    top: 30px;
  }
  & textarea {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
    position: relative;
    top: 50px;
  }
`
