import { Outlet } from 'react-router'

import Nav from '../../components/Nav'

import {
    MainLayoutWrapper,
    Content,
} from './mainLayoutElements'

const MainLayout: React.FC = () => {
    return (
        <MainLayoutWrapper>
            <Nav />
            <Content>
                <Outlet />
            </Content>
        </MainLayoutWrapper>
    )
}

export default MainLayout
