import { companyApiSlice } from '@/features/company/companyApiSlice'
import React, { useEffect, useState } from 'react'
import { sortDataSet } from './functions'
import { Container, Wrapper } from './tableElements'

interface Column {
  header: string
  accessor: string
}

interface TableRowData {
  [key: string]: any
}

interface TableProps {
  columns: Column[]
  data: TableRowData[]
  cellRenderer: (dataKey: string, row: any) => JSX.Element
}

const Table: React.FC<TableProps> = ({ columns, data, cellRenderer }) => {
  // const [tableColumns, setTableColumns] = useState<Column[]>([])
  const [sortedData, setSortedData] = useState<TableRowData[]>(data)
  const [sortingKey, setSortingKey] = useState<string>('')
  const [sortDirection, setSortDirection] = useState<number>(0)

  const handleSort = (key: string): void => {
    const newSortDirection = sortingKey === key && sortDirection === 1 ? -1 : 1
    setSortingKey(key)
    setSortDirection(newSortDirection)
    setSortedData(sortDataSet(sortedData, key, newSortDirection))
  }

  return (
    <>
      <Wrapper>
        <Container>
          <table>
            <thead>
              <tr>
                {columns.map((column: Column, index: number) => (
                  <th key={index} onClick={() => handleSort(column.accessor)}>
                    {column.header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedData.map((item: TableRowData, index: number) => (
                <tr key={index}>
                  {columns.map((column: Column) => (
                    <td key={column.accessor}>{cellRenderer(column.accessor, item)}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Container>
      </Wrapper>
    </>
  )
}

export default Table
