import { apiSlice } from '../../api'
import * as Request from '../../pkg/requests'

export const vacationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserVacationRequests: builder.query<
      Request.IVacationRequest[],
      { userID: number; companyID: number }
    >({
      query: ({ userID, companyID }) => {
        return `/vacation/?user_id=${userID}&company_id=${companyID}`
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
    }),
    getCompanyWideRequests: builder.query({
      query: ({ companyID }: { companyID: number }) => `/vacation/?company_id=${companyID}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
    }),
    requestVacation: builder.mutation({
      query: (request: Request.IVacationRequest) => ({
        url: '/vacation/create',
        method: 'POST',
        body: { ...request },
      }),
    }),
    updateVacation: builder.mutation({
      query: (request: Request.IVacationRequest) => ({
        url: '/vacation/update',
        method: 'PUT',
        body: { ...request },
      }),
    }),
  }),
})

export const {
  useGetUserVacationRequestsQuery,
  useGetCompanyWideRequestsQuery,
  useRequestVacationMutation,
  useUpdateVacationMutation,
} = vacationsApiSlice
