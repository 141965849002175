import { useState } from 'react'
import useOwnUser from 'hooks/useOwnUser'
import { useGetCompanyEmployeesQuery } from '../../../../features/company/companyApiSlice'
import { Wrapper, Table, Container, PopUp, CloseButton } from './employeesElements'
import EditUser from './components/EditUser'
import { FaEdit } from 'react-icons/fa'
import { IEmployee } from '@/pkg/responses'
import { useTranslation } from 'react-i18next'
import SortedUser from './components/UserVacation'


interface HistoryDialogProps {
  open: boolean
  handleClose: () => void
  userId: string | null
  fullName: string
  companyId: number
}

const HistoryDialog = ({ open, handleClose, userId, fullName, companyId }: HistoryDialogProps) => {
  const { t } = useTranslation()
  if (!open) return null

  return (
    <Container>
      <PopUp>
        <CloseButton onClick={handleClose}>{t('Employees:Employee.Close')}</CloseButton>
        <h2>{t('Employees:Employee.Header')}</h2>
        <p>
          {t('Employees:Employee.Paragraf')}
          <b> {fullName}</b>
        </p>
        <SortedUser
          userId={userId ?? ''}
          companyId={companyId ?? 0}
        />
      </PopUp>
    </Container>
  )
}

const EmployeesView: React.FC = () => {
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState<boolean>(false)
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null)
  const [selectedUserName, setSelectedUserName] = useState<string | null>(null)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<IEmployee | null>(null)

  const handleHistoryDialogOpen = (userId: string, firstName: string, lastName: string) => {
    setIsHistoryDialogOpen(true)
    setSelectedUserId(userId)
    setSelectedUserName(`${lastName} ${firstName}`)
  }

  const { t } = useTranslation()
  const user = useOwnUser()
  const {
    data: requests,
    isSuccess,
    refetch,
  } = useGetCompanyEmployeesQuery(
    { companyID: user.selectedCompanyID },
    { skip: !user.selectedCompanyID },
  )

  const handleEditDialogOpen = (entry: IEmployee) => {
    setIsEditDialogOpen(true)
    setSelectedUser(entry)
  }

  const handleEditDialogClose = () => {
    setIsEditDialogOpen(false)
    setSelectedUser(null)
  }

  const handleHistoryDialogClose = () => {
    setIsHistoryDialogOpen(false)
    setSelectedUserId(null)
    setSelectedUserName(null)
  }

  return (
    <Wrapper>
      <Table>
        <thead>
          <tr>
            <th>{t('Employees:Employee.fullName')}</th>
            <th>{t('Employees:Employee.vacationDays')}</th>
            <th>{t('Employees:Employee.startDays')}</th>
            <th>{t('Employees:Employee.usedDays')}</th>
            <th>{t('Employees:Employee.availableDays')}</th>
            <th>{t('Employees:Employee.contractType')}</th>
            <th>{t('Employees:Employee.option')}</th>
          </tr>
        </thead>
        <tbody>
          {requests?.map((entry: IEmployee) => (
            <tr
              key={entry.id.toString()}
              onClick={() =>
                handleHistoryDialogOpen(entry.id.toString(), entry.first_name, entry.last_name)
              }
              style={{ cursor: 'pointer' }}
            >
              <td>{`${entry.last_name} ${entry.first_name}`}</td>
              <td>{entry.vacation_days}</td>
              <td>{entry.start_of_the_year_state}</td>
              <td>{entry.used_through_the_year}</td>
              <td>{entry.available_vacation_days}</td>
              <td>{getContract(entry.contract_type, t)}</td>
              <td>
                <div onClick={(e) => e.stopPropagation()}>
                  <div
                    className='button_hover'
                    onClick={() => handleEditDialogOpen(entry)}
                  >
                    <FaEdit />
                  </div>
                </div>
              </td>
            </tr>
          ))}
          <tr style={{ height: '100%' }} />
        </tbody>
      </Table>
      <EditUser
        open={isEditDialogOpen}
        handleClose={handleEditDialogClose}
        userData={selectedUser}
        refetch={refetch}
      />
      <HistoryDialog
        open={isHistoryDialogOpen}
        handleClose={handleHistoryDialogClose}
        userId={selectedUserId}
        fullName={selectedUserName ?? ''}
        companyId={user.selectedCompanyID}
      />
    </Wrapper>
  )
}

export default EmployeesView

const getContract = (key: string, t: any): string => {
  switch (key) {
    case 'contract_of_employment':
      return t('Employees:Employee.employmentContract')
    case 'contract_of_mandate':
      return t('Employees:Employee.mandateContract')
    case 'contract_for_task':
      return t('Employees:Employee.specificTaskContract')
    case 'b2b':
      return t('Employees:Employee.b2b')
    default:
      return t('Employees:Employee.default')
  }
}
