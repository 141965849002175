import { styled, css } from 'styled-components'
import background from '../../../../../static/images/background.svg'

export const Wrapper = styled.div`
  min-height: 90vh;
  width: 100%;
  background-size: cover;
  background-image: url(${background});
  overflow-y: hidden;
`

export const FormContainer = styled.div`
  & h2 {
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-top: 1.5rem;
    color: ${(props) => props.theme.text.dark};
  }
`

export const Form = styled.form`
  max-width: 450px;
  height: 100%;
  margin: 2rem auto;
  padding: 38px;
  border: 1px solid ${(props) => props.theme.text.dark};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: ${(props) => props.theme.background.white};
  display: flex;
  flex-direction: column;
  row-gap: 29px;

  & label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: lighter;
    font-size: 1.2rem;
    color: ${(props) => props.theme.text.dark};
  }

  & input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid ${(props) => props.theme.text.dark};
    border-radius: 4px;
    box-sizing: border-box;
  }

  & select {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid ${(props) => props.theme.text.light};
    border-radius: 4px;
    box-sizing: border-box;
  }

  & button {
    width: 100%;
    background-color: ${(props) => props.theme.button.light};
    color: ${(props) => props.theme.background.white};
    padding: 14px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-top: 10px;

    &:hover {
      background-color: ${(props) => props.theme.button.hoverBlue};
      color: ${(props) => props.theme.background.white};
    }
  }
`

export const ErrorMessage = styled.p`
  color: ${(props) => props.theme.text.orange};
  font-size: 13px;
`

export const Translate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & h2 {
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: ${(props) => props.theme.text.dark};
  }

  & div {
    width: fit-content;
  }

  & button {
    width: 100%;
    background-color: ${(props) => props.theme.button.light};
    color: ${(props) => props.theme.background.white};
    padding: 14px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-top: 10px;

    &:hover {
      background-color: ${(props) => props.theme.button.hoverBlue};
      color: ${(props) => props.theme.background.white};
    }
  }
`
