import React, { useEffect, useState } from 'react'
import { Wrapper, CloseButton } from './alertElements'

interface AlertProps {
  color: string
  message: string
  show: boolean
  handleClose: () => void
  autoCloseDuration?: number // in milliseconds, default to 5000ms
}

const Alert = ({
  color,
  message,
  show,
  handleClose,
  autoCloseDuration = 5000,
}: AlertProps): React.ReactElement | null => {
  const [backgroundColor, setBackgroundColor] = useState('grey')

  useEffect(() => {
    switch (color) {
      case 'success':
        setBackgroundColor('#388c56')
        break
      case 'error':
        setBackgroundColor('#a63c3c')
        break
      case 'warning':
        setBackgroundColor('#fbac03')
        break
      case 'info':
        setBackgroundColor('#4582ad')
        break
      default:
        setBackgroundColor('grey')
        break
    }
  }, [color])

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        handleClose()
      }, autoCloseDuration)

      return () => clearTimeout(timer) // Clear timeout if the component is unmounted or show changes
    }
  }, [show, handleClose, autoCloseDuration])

  if (!show) return null

  return (
    <Wrapper style={{ backgroundColor }}>
      <span>{message}</span>
      <CloseButton onClick={handleClose}>&times;</CloseButton>
    </Wrapper>
  )
}

export default Alert
