import styled from 'styled-components'

export const Wrap = styled.div`
  width: 90dvw;
`
export const Container = styled.div`
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 1rem;
  font-family: sans-serif;
  width: 100%;

  & thead {
    background: ${(props) => props.theme.gradients.primary};
    color: #ffffff;
    text-align: left;
  }

  & tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  & tr:last-of-type {
    border-bottom: 2px solid ${(props) => props.theme.gradients.primary};
  }

  & td {
    vertical-align: baseline;
    padding: 15px 22px;
  }

  & th {
    padding: 15px 22px;
    &:first-of-type {
      border-radius: 10px 0 0 0;
    }

    &:last-of-type {
      border-radius: 0 10px 0 0;
    }
  }

  & > table {
    border-collapse: collapse;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.12);
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
    width: 100%;

    @media screen and (max-width: 768px) {
      display: block;
      max-width: 90dvw;
      overflow-x: scroll;
      border-radius: 10px;
    }
  }
`
export const Pop = styled.div`
  & > button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 1rem;
    position: relative;
    left: 300px;
    top: -35px;

    & button:hover {
      background-color: ${(props) => props.theme.primary.dark};
    }

    &:first-of-type {
      background-color: red;
      color: white;
    }
  }
`

export const PopUp = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  @media screen and (max-width: 768px) {
    max-width: 90dvw;
    max-height: 90dvh;
    top: 5dvh;
    left: 5dvw;
  }
`

export const PopUpContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); */
  width: 650px;
  height: auto;

  & > button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 1rem;
    position: relative;
    left: 520px;

    & button:hover {
      background-color: ${(props) => props.theme.primary.darker};
    }

    &:first-of-type {
      background-color: ${(props) => props.theme.button.light};
      color: white;
    }
  }
`

export const Coc = styled.div`
  display: flex;

  & h2 {
    color: ${(props) => props.theme.gradients.primary};
    font-weight: 400;
    font-size: 2rem;
  }

  & > button {
    padding: 15px 25px 15px 25px;
    border: none;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    background-color: ${(props) => props.theme.button.blue};
    transition:
      background-color 0.3s ease,
      box-shadow 0.3s ease;

    &:hover {
      background-color: ${(props) => props.theme.button.hoverBlue};
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    }

    &:active {
      transform: translateY(2px);
    }
  }
`

export const DaysCount = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.button.blue};
  margin-right: 10px;
  font-size: 1.1rem;
  margin-left: 1rem;
  align-content: center;
  font-weight: 500;

  & p {
    margin-right: 7px;
    font-size: 1.1rem;
    color: ${(props) => props.theme.button.blue};
  }
`
