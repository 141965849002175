import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BackGround = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const Form = styled.form`
  background: linear-gradient(to bottom, #6495ed, #4682b4);
  border-radius: 10px;
  padding: 20px;
  width: 500px;
  text-align: center;
  color: white;
  position: relative;

  h1 {
    margin-bottom: 20px;
    font-size: 20px;
  }

  .spacer {
    margin: 20px 0;
  }

  button {
    width: 45%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin: 10px;

    &.cancel {
      background: #ff0000;
      color: white;
    }

    &.submit {
      background: #32cd32;
      color: white;
    }
  }
`

export const InfoBox = styled.div`
  background: #ffffff;
  color: #000000;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  text-align: left;
  word-wrap: break-word;

  h4 {
    margin-bottom: 10px;
  }

  p {
    margin: 0;
  }
`
