import { useEffect } from 'react'
import { QueryActionCreatorResult } from '@reduxjs/toolkit/query'
import { useForm, Controller } from 'react-hook-form'
import useOwnUser from 'hooks/useOwnUser'
import { ICompanyProfileUpdateRequest } from '@pkg/requests'
import { useUpdateCompanyProfileMutation } from '../../../../../../features/companyProfiles/companyProfilesApiSlice'

import {
  Dialog,
  Wrapper,
  IconButton,
  Background,
  TextFieldStyle,
  SelectStyle,
  SubmitButton,
} from './editUserElements'
import { FaX } from 'react-icons/fa6'
import { t } from 'i18next'

interface EditUserProps {
  open: boolean
  userData: any
  handleClose: () => void
  refetch: () => QueryActionCreatorResult<any>
}

interface TextFieldProps {
  name: string
  label: string
  value: string | number
  type: 'text' | 'number'
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
}

const TextField = ({ ...props }: TextFieldProps): JSX.Element => {
  return (
    <TextFieldStyle>
      <label htmlFor={props.name}>{props.label}</label>
      <input
        onChange={props.onChange}
        value={props.value}
        name={props.name}
        type={props.type}
      />
    </TextFieldStyle>
  )
}

interface SelectProps {
  name: string
  label: string
  value: string | number
  children: React.ReactNode
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
}

const Select = ({ ...props }: SelectProps): JSX.Element => {
  return (
    <SelectStyle>
      <label htmlFor={props.name}>{props.label}</label>
      <select
        value={props.value}
        onChange={props.onChange}
        name={props.name}
      >
        {props.children}
      </select>
    </SelectStyle>
  )
}

const EditUser = ({ ...props }: EditUserProps): JSX.Element | null => {
  const [updateProfile] = useUpdateCompanyProfileMutation()
  const { selectedCompanyID } = useOwnUser()
  const { control, reset, handleSubmit } = useForm<ICompanyProfileUpdateRequest>()

  const onSubmit = (data: ICompanyProfileUpdateRequest): void => {
    data.company_id = selectedCompanyID
    data.user_id = props.userData.id
    data.available_vacation_days = Number(data.available_vacation_days)
    data.base_vacation_days = Number(data.base_vacation_days)
    data.start_of_the_year_state = Number(data.start_of_the_year_state)
    data.used_through_the_year = Number(data.used_through_the_year)
    updateProfile(data)
      .then((res: any) => {
        console.log(res)
      })
      .then(() => props.refetch())
      .then(() => props.handleClose())
  }

  useEffect(() => {
    if (props.userData) {
      reset({ ...props.userData, base_vacation_days: props.userData.vacation_days })
    }
  }, [props.userData])

  if (!props.open) return null

  return (
    <Wrapper>
      <Background />
      <Dialog>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h1>{t('Employees:EditUser.user')}</h1>
          <IconButton onClick={props.handleClose}>
            <FaX />
          </IconButton>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name='base_vacation_days'
            render={({ field }) => (
              <TextField
                {...field}
                name='base_vacation_days'
                label={t('Employees:EditUser.baseVacationDays')}
                type='number'
              />
            )}
          />
          <Controller
            control={control}
            name='available_vacation_days'
            render={({ field }) => (
              <TextField
                {...field}
                name='available_vacation_days'
                label={t('Employees:EditUser.availableVacationDays')}
                type='number'
              />
            )}
          />
          <Controller
            control={control}
            name='start_of_the_year_state'
            render={({ field }) => (
              <TextField
                {...field}
                name='start_of_the_year_state'
                label={t('Employees:EditUser.startOfTheYearState')}
                type='number'
              />
            )}
          />
          <Controller
            control={control}
            name='used_through_the_year'
            render={({ field }) => (
              <TextField
                {...field}
                name='used_through_the_year'
                label={t('Employees:EditUser.usedThroughoutTheYear')}
                type='number'
              />
            )}
          />
          <Controller
            control={control}
            name='contract_type'
            render={({ field }) => (
              <Select
                {...field}
                name='contract_type'
                label={t('Employees:EditUser.contractType')}
              >
                <option value='contract_of_employment'>
                  {t('Employees:EditUser.contractOfEmployment')}
                </option>
                <option value='contract_of_mandate'>
                  {t('Employees:EditUser.contractOfMandate')}
                </option>
                <option value='contract_of_task'>{t('Employees:EditUser.contractOfTask')}</option>
                <option value='b2b'>{t('Employees:EditUser.B2B')}</option>
                <option value='no_contract'>{t('Employees:EditUser.noContract')}</option>
              </Select>
            )}
          />
          <SubmitButton type='submit'>{t('Employees:EditUser.save')}</SubmitButton>
        </form>
      </Dialog>
    </Wrapper>
  )
}

export default EditUser
