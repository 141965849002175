import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useChangePasswordMutation } from '../../../../../features/auth/authApiSlice'
import { IChangePassword } from '../../../../../pkg/requests'
import { Form, FormContainer, Wrapper, ErrorMessage, Translate } from './changePasswordElements'
import { useChangePasswordValidation } from '../../../../../pkg/validators'
import { yupResolver } from '@hookform/resolvers/yup'
import { IRTKQueryResponse } from '../../../../../pkg/UI'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'

const LanguageSwitcher: React.FC = () => {
  const { t, i18n } = useTranslation()

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language)
    Cookies.set('i18next', language, { expires: 365 })
  }

  return (
    <Translate>
      <h2>{t('Root:PasswordChange.Translation')}</h2>
      <div>
        <button onClick={() => changeLanguage('en')}>EN</button>
        <button onClick={() => changeLanguage('pl')}>PL</button>
      </div>
    </Translate>
  )
}

const PasswordChangeForm: React.FC = () => {
  const ChangePasswordSchema = useChangePasswordValidation()
  const { t } = useTranslation()
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IChangePassword>({
    resolver: yupResolver(ChangePasswordSchema),
  })

  const [changePassword] = useChangePasswordMutation()
  const [submitStatus, setSubmitStatus] = useState<string | null>(null)

  const onSubmit = async (data: IChangePassword) => {
    try {
      const response: IRTKQueryResponse = await changePassword(data).unwrap()
      if ('data' in response) setSubmitStatus(response.data.message)
    } catch {
      setSubmitStatus('Zmiana hasła nie powiodła się')
    }
  }

  return (
    <Wrapper>
      <LanguageSwitcher />
      <FormContainer>
        <h2>{t('Root:PasswordChange.ChangeYourPassword')}</h2>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name='password'
            render={({ field }) => (
              <div>
                <label htmlFor='password'>{t('Root:PasswordChange.CurrentPassword')}</label>
                <input
                  type='password'
                  id='password'
                  {...field}
                />
                {errors.password && errors.password.message ?
                  <ErrorMessage>{errors.password.message}</ErrorMessage>
                : null}
              </div>
            )}
          />
          <Controller
            control={control}
            name='new_password1'
            render={({ field }) => (
              <div>
                <label htmlFor='new_password1'>{t('Root:PasswordChange.NewPassword')}</label>
                <input
                  type='password'
                  id='new_password1'
                  {...field}
                />
                {errors.new_password1 && errors.new_password1.message ?
                  <ErrorMessage>{errors.new_password1.message}</ErrorMessage>
                : null}
              </div>
            )}
          />
          <Controller
            control={control}
            name='new_password2'
            render={({ field }) => (
              <div>
                <label htmlFor='new_password2'>{t('Root:PasswordChange.RepeatPassword')}</label>
                <input
                  type='password'
                  id='new_password2'
                  {...field}
                />
                {errors.new_password2 && errors.new_password2.message ?
                  <ErrorMessage>{errors.new_password2.message}</ErrorMessage>
                : null}
              </div>
            )}
          />
          <button type='submit'>{t('Root:PasswordChange.ChangePassword')}</button>
          {submitStatus && <p>{submitStatus}</p>}
        </Form>
      </FormContainer>
    </Wrapper>
  )
}

export default PasswordChangeForm
